import React, { useState, useEffect } from 'react';

const TamagotchiGame = () => {
  const [pet, setPet] = useState({
    name: 'My doggie',
    hunger: 50,
    happiness: 50,
    energy: 50,
    credits: 0,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setPet(prevPet => ({
        ...prevPet,
        hunger: Math.max(prevPet.hunger - 1, 0),
        happiness: Math.max(prevPet.happiness - 1, 0),
        energy: Math.max(prevPet.energy - 1, 0),
      }));
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  const feed = () => {
    setPet(prevPet => ({
      ...prevPet,
      hunger: Math.min(prevPet.hunger + 10, 100),
    }));
  };

  const play = () => {
    setPet(prevPet => ({
      ...prevPet,
      happiness: Math.min(prevPet.happiness + 10, 100),
      energy: Math.max(prevPet.energy - 5, 0),
    }));
  };

  const rest = () => {
    setPet(prevPet => ({
      ...prevPet,
      energy: Math.min(prevPet.energy + 10, 100),
    }));
  };

  const watchAd = () => {
    alert("Watching an ad...");
    setPet(prevPet => ({
      ...prevPet,
      credits: prevPet.credits + 5,
    }));
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      minHeight: '100vh', 
      fontFamily: 'Arial, sans-serif'
    }}>
      <h1 style={{ fontSize: '2rem', marginBottom: '1rem' }}>{pet.name}</h1>
      <div style={{ 
        width: '200px', 
        height: '200px', 
        backgroundColor: '#f0f0f0', 
        borderRadius: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        fontSize: '5rem',
        marginBottom: '1rem'
      }}>
        🐶
      </div>
      <div style={{ width: '300px', marginBottom: '1rem' }}>
        {['Hunger', 'Happiness', 'Energy'].map(stat => (
          <div key={stat} style={{ marginBottom: '0.5rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.25rem' }}>
              <span>{stat}:</span>
              <span>{pet[stat.toLowerCase()]}%</span>
            </div>
            <div style={{ 
              width: '100%', 
              backgroundColor: '#e0e0e0', 
              borderRadius: '9999px', 
              height: '10px'
            }}>
              <div style={{
                width: `${pet[stat.toLowerCase()]}%`,
                backgroundColor: stat === 'Hunger' ? '#3b82f6' : stat === 'Happiness' ? '#10b981' : '#f59e0b',
                height: '100%',
                borderRadius: '9999px'
              }}></div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginBottom: '1rem' }}>
        {['Feed', 'Play', 'Rest'].map(action => (
          <button 
            key={action} 
            onClick={() => eval(action.toLowerCase())()} 
            style={{
              margin: '0 0.25rem',
              padding: '0.5rem 1rem',
              backgroundColor: '#4b5563',
              color: 'white',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer'
            }}
          >
            {action}
          </button>
        ))}
      </div>
      <div>
        <p>Credits: {pet.credits}</p>
        <button 
          onClick={watchAd}
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#4b5563',
            color: 'white',
            border: 'none',
            borderRadius: '0.25rem',
            cursor: 'pointer'
          }}
        >
          Watch Ad
        </button>
      </div>
    </div>
  );
};

export default TamagotchiGame;