import logo from './logo.svg';
import './App.css';
import MyDoggie from './MyDoggie';

function App() {
  return (
    <div className="App">
      <MyDoggie />
    </div>
  );
}

export default App;
